import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context";
import BrandTable from "./components/BrandTable";
import MDButton from "components/MDButton";
import CreateBrand from "./components/CreateBrand";
import { useEffect, useState } from "react";
import { getBrands } from "store/slice/brands/brandSlice";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getUsers } from "store/slice/users/userSlice";

const Brands = () => {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [dialogOpen, setDialogOpen] = useState(false);
  const brandsList = useSelector((state) => state.brands.brandsList);
  const isLoading = useSelector((state) => state.brands.loading);
  const userData = useSelector((state) => state.users?.meInfo);
  const userList = useSelector((state) => state.users?.users || []);
  const roleModules = userData?.roleModules || {};
  const roleLevel = userData?.roleLevel;

  useEffect(() => {
    if (isEmpty(brandsList)) {
      dispatch(getBrands());
    }
    if (roleLevel === 100 && isEmpty(userList)) {
      dispatch(getUsers());
    }
  }, [dispatch, brandsList, roleLevel, userList]);

  const updateData = async () => {
    await dispatch(getBrands());
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={1}
                  mt={-2}
                  py={1}
                  px={1}
                  variant="gradient"
                  bgColor={sidenavColor}
                  borderRadius="lg"
                  coloredShadow={sidenavColor}
                  className="d-flex align-items-center justify-content-between"
                >
                  <MDTypography variant="h6" color="white">
                    Brands
                  </MDTypography>
                  {roleModules.Brand_add && (
                    <MDButton
                      variant="outlined"
                      size="small"
                      onClick={() => setDialogOpen(true)}
                    >
                      Create Brands
                    </MDButton>
                  )}
                </MDBox>
                <MDBox pt={3}>
                  <BrandTable
                    brandsData={brandsList}
                    roleModules={roleModules}
                    updateParentData={updateData}
                    loading={isLoading}
                  />
                  <CreateBrand
                    dialogOpen={dialogOpen}
                    onClose={() => {
                      setDialogOpen(false);
                    }}
                    userList={userList}
                    updateParentData={updateData}
                    roleLevel={roleLevel}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default Brands;
